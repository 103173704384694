import { CssBaseline } from '@mui/material';
import { chameleonTheme } from '@mvf/external-components';
import { ThemeProvider, Global } from '@emotion/react';
import makeGlobalStyling from '../styles/makeGlobalSlyles';

const Index = () => {
  return (
    <ThemeProvider theme={chameleonTheme}>
      <CssBaseline />
      <Global styles={makeGlobalStyling()} />
    </ThemeProvider>
  );
};

export default Index;
