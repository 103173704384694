import { css } from '@emotion/react';

const makeGlobalStyling = () => {
  return css({
    body: {
      minHeight: '100%',
      height: '100vh',
      touchAction: 'pan-x pan-y',
      backgroundColor: 'transparent !important', // defer to widget background color.
    },
    '#__next': {
      height: '100%',
    },
  });
};

export default makeGlobalStyling;
